import React from 'react';
import { history } from '../../../../../redux/history'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { ArrowLeftCircleIcon } from '@heroicons/react/24/outline';
class DetailHeader extends React.Component {

    handleClick() {
        // history.location
        history.goBack()
    }
    render() {
        const {  title, meta, t } = this.props
        return (

            <>
                <div style={{padding:"0px 22px 22px 22px", borderBottom:"1px solid #D1D5DB"}}>
                    <div style={{display:'flex', alignItems:"center", gap:"10px"}}>
                    <div onClick={this.handleClick.bind(this)}><ArrowLeftCircleIcon height="25px" width="24px" color="#2563EB"/></div>
                        <h5 className="page-title">
                            {t(meta && meta.title) || t(title)}
                        </h5>
                        
                        {/* <button className="btn btn-link pull-right" onClick={this.handleClick.bind(this)}>{t('Back to List', { name: meta.name })}</button> */}
                    </div>

                </div>
            </>
        )
    }

}
export default withTranslation()(DetailHeader)
DetailHeader.propTypes = {
    title: PropTypes.string, name: PropTypes.string
};
