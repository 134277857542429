import React, { Component } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, Button, ButtonGroup, Badge } from 'reactstrap';
import "./react-select.css";
import "react-virtualized-select/styles.css";
import Select from "react-virtualized-select";
import Switch from "react-switch";
import { ChevronUpDownIcon } from '@heroicons/react/24/outline'
// Locale
import { withTranslation } from 'react-i18next';
import { Box } from "@chakra-ui/react";
//Locale

class Fsort extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false,
      cSelected: [],
      checked: true,
      fields: this.props.fields,
      columns: this.props.columns,
      sorts: [],
      schemaModel:null
    };
    this.onRadioBtnClick = this.onRadioBtnClick.bind(this);
  }
componentDidMount(){
  const {entitySchema}=this.props
  let schemaModel= entitySchema && entitySchema.describe().children
  this.setState({schemaModel})
}

  onRadioBtnClick(rSelected) {
    this.setState({ rSelected });
  }

  handleKeepSort(checked) {
    const { sorts } = this.state;
    this.setState({ checked });
    if(checked){
      if (this.isValid(sorts)) {
        let sort = sorts && sorts.map(s=>`${this.checkJsonSort(s.name)} ${s.sortOrder}`)
        this.props.handleSort(sort)
      }
    }
    else {
      let sortValues=[];
      this.props.handleSort(sortValues)
    }
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  handleFieldChange(index, e) {
    const { sorts } = this.state;
    let newSorts = sorts && sorts.map((f, i) => {
      if (index === i)
        f.name = e && e.value ? e.value : ''
      return f;
    })
    this.setState({
      sorts: newSorts
    })

    if (this.isValid(newSorts)) {
      let sort = newSorts && newSorts.map(s=>{
        
        return `${this.checkJsonSort(s.name)} ${s.sortOrder}`
      })
      this.props.handleSort(sort)
    }
  }

  handleNewSortAdd(e) {
    if (!e) return true;
    const { sorts } = this.state;
    let newSorts = [...sorts, { name: e.value, sortOrder: '', sort: e.value }]
    this.setState({
      sorts: newSorts
    })
  }

  handleSortOrderChange(index, sortOrder, e) {
    if (!sortOrder) return true;
    const { sorts } = this.state;
    let newSorts = sorts && sorts.map((f, i) => {
      if (index === i)
        f.sortOrder = sortOrder ? sortOrder : ''
      return f;
    })
    this.setState({
      sorts: newSorts,
      dropdownOpen: this.state.dropdownOpen ? false : true
    })

    if (this.isValid(newSorts)) {
      let sort = newSorts && newSorts.map(s=>`${this.checkJsonSort(s.name)} ${s.sortOrder}`)
      this.props.handleSort(sort)
    }

  }

  checkJsonSort(fieldName){
    const {schemaModel}= this.state;
    if(schemaModel && schemaModel[fieldName])
     return fieldName;
    for(let key in schemaModel){
      if (schemaModel.hasOwnProperty(key)) {
        let obj = schemaModel[key];
        if(obj && obj.type === "object" && obj.children[fieldName]){
          fieldName = `${key}_${fieldName}` 
          break;
        }
      }
    }
    return fieldName;
  }

  handleDeleteSort(index, e) {
    const { sorts } = this.state;
    let newSorts = sorts.filter((f, i) => i !== index)
    if (this.isValid(newSorts)) {
      let sort = newSorts && newSorts.map(s=>`${this.checkJsonSort(s.name)} ${s.sortOrder}`)
      this.props.handleSort(sort)
    }
    this.setState({
      sorts: newSorts ? newSorts : []
    })
  }

  isValid(sorts) {
    let valid = sorts && sorts.every((sort, index) => {
      if (sort.name === "" || sort.sortOrder === "")
        return false;
      else
        return true
    })
    return valid;
  }



  render() {
    const { columns, sorts } = this.state;
    const { t } = this.props;
    let options = columns && columns.pop && columns.map((f, index) => {
      return { label: t(f.label), value: f.key||f.name, disabled: sorts && sorts.some(sort=>sort.name===f.key),params:f.params}
    })
    options = options && options.pop && options.filter(f => {
      let sortable = this.props.fields && this.state.fields[f.value] && this.state.fields[f.value].sortable;
      return (sortable === undefined || sortable === true ? true : false)
    })
    return (
      <div className="for-hide-fields wd-2">

        <Dropdown className="text-left asc-transparent-btn" size="sm" isOpen={this.state.dropdownOpen} toggle={this.toggle}>
          <DropdownToggle color="transparent" style={{borderRadius:"6px", padding:"6px 10px", border:"1px solid #D1D5Db"}}>
            {/* <span className="pr-1" ><i className="fa fa-sort"></i></span> {t("Sort")} */}
            <Box display="flex" alignItems="center" gap="5px"><ChevronUpDownIcon height="22px" width="22px"/>

{sorts && sorts.length>0 && <Badge color="success" pill>{sorts && sorts.length}</Badge>}</Box>
            
        </DropdownToggle>
          <DropdownMenu>
            <ul className="fields-list pb-1 ">
              {sorts && sorts.length < 1 && <li><span className="no-sort">{t("No Sort applied")} </span>
              </li>}
              {sorts && sorts.length > 0 && sorts.map((sort, index) =>
                <li className="clearfix" key={index}>
                  <span className="for-delete pull-left pr-3 mt-3 " onClick={this.handleDeleteSort.bind(this, index)}><i className="fa fa-trash"></i></span>
                  <div className="b-left pull-right">
                    <span className="for-label-text pt-3">{t("Sort by")}</span>
                    <span className="for-label-drop p-2 align-top"><Select placeholder={t("Field Name")} options={options} value={options && options.find(o => o.value === sort.name)} onChange={this.handleFieldChange.bind(this, index)} /></span>
                    <span className="for-label-drop p-2 align-center">{t("from")}<span className="for-switch"><label> <ButtonGroup>
                      <Button className="pl-1" color="primary" size="sm" onClick={this.handleSortOrderChange.bind(this, index, 'asc')} active={sort.sortOrder === 'asc'}>{t('A-Z')}</Button>
                      <Button className="pr-1" color="primary" size="sm" onClick={this.handleSortOrderChange.bind(this, index, 'desc')} active={sort.sortOrder === 'desc'}>{t('Z-A')}</Button>
                    </ButtonGroup> </label>
                    </span></span>

                  </div>
                </li>
              )
              }

            </ul>
            <div className="pb-5 pick-another"><span className="for-label-drop  align-top">
              <Select placeholder={t("Pick another field")} options={options} onChange={this.handleNewSortAdd.bind(this)} />
            </span>
            </div>
            <div className="filter-btn clear-fix mt-1  fixed-bottom asc-grey pl-2 pt-2 " style={{ zIndex: 0 }}>
              <span className="for-switch"> <label> <Switch uncheckedIcon={false} checkedIcon={false} height={13} width={20} onChange={this.handleKeepSort.bind(this)} checked={this.state.checked} /> {t("keep Sorted")} </label>
              </span>
            </div>


          </DropdownMenu>
        </Dropdown>
      </div>
    )
  }
}

export default withTranslation()(Fsort);
