import React, { Component } from "react";
// import Select from "react-virtualized-select";
// Locale
import { withTranslation } from 'react-i18next';
import Switch from "react-switch";
import "react-virtualized-select/styles.css";
import { Badge, Dropdown, DropdownMenu, DropdownToggle, Input } from 'reactstrap';
import "./react-select.css";
import { Box, Text } from "@chakra-ui/react";
import { EyeIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@chakra-ui/icons";
//Locale


class Fhide extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false,
      columns: this.props.columns,
      droupDownColumns: this.props.columns,
      fields: this.props.fileds,
      searchText:''
    };
    this.handleSearch = this.handleSearch.bind(this);
  }

  handleChange(name, checked) {
   this.props.handleHideField(name, checked);
  }
  handleHideShowAllChange(showAll) {
    this.props.handleHideShowAllField(showAll);
   }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  handleSearch(e){
   const { value } = e.target;
   let columns = this.state.droupDownColumns.filter(column=>column.label.toLowerCase().includes(value.toLowerCase()))
   this.setState({
    columns
   })
  }


  render() {
    const { columns} = this.state;
    const { t } = this.props;
    let countHideFields = columns && columns.filter(f=>!f.shown)
    return (
      <div className="for-hide-fields">

        <Dropdown className="text-left asc-transparent-btn" size="sm" isOpen={this.state.dropdownOpen} toggle={this.toggle}>
          <DropdownToggle color="transparent"  className="secondaryButton">
          <Box display="flex" alignItems="center" justifyContent="space-between" minW="150px">
              <Box display="flex" alignItems="center" gap="5px">
                <EyeIcon height="20px" width="20px"/>
                <Text fontSize="14px" color="#6B7280" fontWeight="400" lineHeight="normal">{t("Hide Fields")}</Text>
                {countHideFields && countHideFields.length>0 && <Badge color="success" pill>{countHideFields && countHideFields.length}</Badge>}
              </Box>
              <ChevronDownIcon height="20px" width="20px" />
            </Box>
            {/* <span className="pr-2"> <i className="fa fa-eye-slash"></i></span>{t("Hide Fields")} */}
            {/* {countHideFields && countHideFields.length>0 && <Badge color="success" pill>{countHideFields && countHideFields.length}</Badge>} */}
        </DropdownToggle>
          <DropdownMenu>
            <div className="search-drop"><span ><Input bsSize="sm" name="searchText" id="searchText" onChange={this.handleSearch} placeholder={t("Find a field")} /></span></div>
            <ul className="fields-list mt-1">
            { columns && columns.length > 0 && columns.map((f, i) => {
              return (<li className="clearfix"  key={i}>
                <div className="b-left pull-left hideField-switch">
                  <span className="for-switch pr-2"><label> <Switch uncheckedIcon={false} checkedIcon={false} height={12} width={17} onChange={this.handleChange.bind(this, f.name)} checked={f.shown} value={f.label} /> </label>
                  </span>
                  <span className="for-icon-text"><i className="fa fa-address-card"></i> { t(f.label)}</span>
                  {/* fields && fields[f.name] ? fields[f.name].label : */}
                  {/* <span className="for-division"><Select placeholder="Division" options={options} value={this.state.selectedDivision1} onChange={(e) => this.setState({ selectedDivision1: e })} /></span> */}
                </div>
                {/* <span className="for-drag pull-right pt-1"><i className="fa fa-grip-vertical"></i></span> */}
              </li>)
            })
            }
            </ul>
            <div className="filter-btn clear-fix mt-1 ">
          <button className="pull-left mb-2" size="sm" onClick={this.handleHideShowAllChange.bind(this,false)}>{t("Hide All")}</button> <button className="pull-right" size="sm" onClick={this.handleHideShowAllChange.bind(this,true)}>{t('Show all')}</button>
            </div>


          </DropdownMenu>
        </Dropdown>
      </div>
    )
  }
}
export default withTranslation()(Fhide);
