
import React, { useMemo, useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { Box, Button, Avatar, Badge, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import { identityServices } from '..';

const redirectURL = `${window.location.origin}/agent-ui-v2`;
const availableTemplates = ['product suggestion', 'provide feedback', 'report an issue', 'data purging'];

const formatDate = (inputDate) => {
  const date = new Date(inputDate);

  const options = {
    month: 'short',
    day: '2-digit', // Day with leading zero (e.g., 25)
    year: 'numeric', // Full year (e.g., 2024)
    hour: '2-digit', // Hour in 12-hour format
    minute: '2-digit', // Minutes with leading zero
    hour12: true, // Use 12-hour format with AM/PM
  };

  return date.toLocaleString('en-US', options);
};
const SupportRequests = (props) => {
  const { profile } = props || null;
  const [data, setData] = useState([]);
  const [offers, setOffers] = useState([]);
  const pUser = profile?.preferred_username;
  const roles = profile?.roles || [];
  const tenantName = profile?.tenantId || null;
  const getSupportRequest = async (tenantName) => {
    try {
      let apiUrl = sessionStorage.getItem('apiUrl');
      apiUrl = apiUrl.replace('v1', 'v2');
      const dataFilter= {search:"", top:100, offset:0, sort:"updatedAt", searchOrder:"DESC", filter:[{
        op: "EQ",
        column: "summary",
        operand: `${tenantName}`,
      }]}
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          isSupportRequest: 'true',
        },
        url: `${apiUrl}/ticketing/ticket/list`,
        data:  JSON.stringify(dataFilter)
      };
      const response = await identityServices.identityApi.request(
        requestOptions
      );
      if (response.data) {
        return {
          error: null,
          response: response.data,
        };
      }
    } catch (error) {
      return { error: 'something went wrong', response: null };
    }
    return { error: 'something went wrong', response: null };
  };

  const fetchOffer = async () => {
    try {
      let apiUrl = sessionStorage.getItem('apiUrl');
      apiUrl = apiUrl.replace('v1', 'v2');
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          isSupportRequest: 'true',
        },
        url: `${apiUrl}/ticketing/tenant/sc/offers`
      };
      const response = await identityServices.identityApi.request(
        requestOptions
      );
      if (response.data) {
        return {
          error: null,
          response: response.data,
        };
      }
    } catch (error) {
      return { error: 'something went wrong', response: null };
    }
    return { error: 'something went wrong', response: null };
  };

  useEffect(() => {
    getSupportRequest(tenantName).then((res) => {
      if (res.response) {
        setData(res.response || []);
      }
    });
    fetchOffer().then((res) => {
      if (res.response) {
        setOffers(res?.response?.offers)
      }
    });
  }, []);
  const setTicketFormat =  (propsData, tenantName, userProfile, offers) => {
    // Define dynamic keys to be used
    const dynamicKeys = [
      'subject',
      'status',
      'priority',
      'created_at',
      'submittedby',
      'requestor',
    ];
  
    // Destructure the necessary properties from props
    const { list, itemTypes } = propsData;
    const pUser =
      userProfile?.username || userProfile?.preferred_username;
    const roles = userProfile?.roles || [];
    if (!pUser && !roles) return [];
    // Initialize the array to store the results
    const getItemType = [];
  
    // Ensure the list and tenantName are defined
    if (!list || !tenantName) {
      return [];
    }
  
    // Filter the list based on the tenant name
    // const filteredList = list.filter(
    //   (x) =>
    //     x?.summary?.toLowerCase() === tenantName.toLowerCase() ||
    //     x?.data?.customer === tenantName
    // );
  
    // // Check if the filtered list has any items
    // if (filteredList.length === 0) {
    //   return [];
    // }
  
    // Iterate over the filtered list
    list.forEach((item) => {
      let serializeField = {
        caseId: item?.object_id || item?.data?.object_id || '',
        submittedOn: item?.created_at || item?.updated_at || '',
        toWatchers: item?.data?.toWatchers || [],
        ccWatchers: item?.data?.ccWatchers || [],
        submittedby: '',
        requestor: '',
        submittedByUserName: '',
        // eslint-disable-next-line no-mixed-operators
        offerName: item?.data?.offerId && Array.isArray(offers) && offers?.find((x) => x?.id === item?.data?.offerId)?.name || ''
      };
      serializeField.submittedOn = serializeField?.submittedOn && moment(serializeField.submittedOn).local().format('MMMM-DD, YYYY hh:mm A');
  
      // Ensure item.lookup_props and itemTypeId are defined
      const itemTypeId = item?.lookup_props?.itemTypeId;
      if (!itemTypeId || !itemTypes[itemTypeId]) {
        getItemType.push(serializeField);
        return;
      }
  
      const itemTypeDef = itemTypes[itemTypeId]?.definition;
      if (!itemTypeDef) {
        getItemType.push(serializeField);
        return;
      }
      // Iterate over the fields of the item type definition
      itemTypeDef.fields.forEach((elem) => {
        if (dynamicKeys.includes(elem?.label?.toLowerCase())) {
          if (elem?.label?.toLowerCase() === 'status') {
            const {
              workflow: { workflow_status },
            } = itemTypeDef;
            const fndStatus = workflow_status?.find(
              (x) => x?.id === item?.data[elem.id]
            );
            serializeField = {
              ...serializeField,
              [elem?.label?.toLowerCase()]:
                fndStatus?.data?.label || item?.data[elem.id] || null,
            };
          } else {
            serializeField = {
              ...serializeField,
              [elem?.label?.toLowerCase()]: item?.data[elem.id] || null,
            };
          }
        }
      });
  
      // Ensure submittedBy is defined and has the necessary properties
      if (item?.data?.submittedBy) {
        const sBy = item.data.submittedBy;
        if (sBy?.firstName || sBy?.lastName || sBy?.userName) {
          serializeField = {
            ...serializeField,
            submittedby: `${sBy.firstName ? sBy.firstName : sBy.userName} ${sBy.lastName}`,
            submittedByUserName: sBy.userName.split('@')[0].split('.').join(' '),
            requestor: item?.data?.chatSummary || item?.data?.requestedFor || null
          };
        }
      }
  
      if (roles?.includes('ticket_admin')) {
        getItemType.push(serializeField);
      } else if (
        serializeField?.requestor === pUser ||
        serializeField?.submittedByUserName === pUser ||
        serializeField?.toWatchers.includes(pUser) ||
        serializeField?.ccWatchers.includes(pUser)
      ) {
        getItemType.push(serializeField);
      }
    });
    return getItemType;
  };
  // const ticketList = useMemo(() => {
  //   if (data !== undefined && data?.list) {
  //     return setTicketFormat(data);
  //   }
  // }, [data]);

  const ticketList = useMemo(() => {
    if (data !== undefined && data?.list) {
      return setTicketFormat(data, tenantName, profile, offers);
    }
  }, [data, tenantName, profile, offers]);

  const currentOffers=useMemo(()=>{
    const allOffers = offers
    return allOffers && Array.isArray(offers) && allOffers.filter((item)=>availableTemplates.some((template) => 
      item.item_type_name.toLowerCase().includes(template.toLowerCase())
  ))
  },[offers]);

  const onCreateClick = useCallback((elem)=>{
    window.open(`${redirectURL}/support/create/${elem?.item_type}/${elem?.id}`, '_blank');
  },[])
  return (
    <Box color="gray" fontFamily={"Inter!important"} fontStyle="normal">
      <Box display="flex" pb={4}>
        <Box w="50%" color="black" fontSize="13px" fontWeight="bold">
          Support Ticket
        </Box>
        <Box w="50%" textAlign="right">
          {/* <Button
            backgroundColor="#5887f0"
            onClick={() => window.open(`${redirectURL}/support`, '_blank')}
            color="white"
            fontSize="12px"
            borderRadius="3px"
            size="xs"
          >
            Create Ticket
          </Button> */}
            <Menu>
    <MenuButton 
      as={Button}            
      backgroundColor="#5887f0"
      color="white"
      fontSize="12px"
      borderRadius="3px"
      _hover={{ bg: '#5887f0' }}
      _expanded={{ bg: 'blue.400' }}
      size="xs" 
    >
  Create Ticket
    </MenuButton>
  <MenuList>
    {
      currentOffers?.map((item) => <MenuItem onClick={()=> onCreateClick(item)} key={item?.name}>{item?.name || item?.item_type_name || ''}</MenuItem>)
    }

  </MenuList>
  </Menu>
        </Box>
      </Box>
      {/* Ticket Listing Starts Here */}
      <Box w="100%" maxH="560px" overflow="auto">
        {ticketList?.length === 0 && (
          <Box color="gray" textAlign="center" mt={10}>
            No Support Request Found!
          </Box>
        )}

{ticketList?.map((item, id) => (
          <Box
          key={item.caseId}
          display="flex"
          justifyContent="space-between"
          mt={4}
          borderBottom="thin solid #e2e2e2"
          pb={3}
          fontSize="0.85rem"
          color={"#000"}
          cursor={'pointer'}
          onClick={()=>window.open(
            `${redirectURL}/support/${item.caseId}`,
            '_blank' 
          )}
          mr={1}
        >
          <Box w="60%"  borderLeft="4px solid gray" paddingLeft="10px">
            <Box display="flex" fontSize=".85rem">
              <Box>
                <Avatar mr={1} size="xs" name={item.submittedByUserName} />
              </Box>
              <Box>
                  <Box fontWeight={"500"}>{item.caseId} {item?.offerName ? `( ${item?.offerName} )` : ''}</Box>
                <Badge mt={1}>
                {item.status}
              </Badge>
                <Box>{item.subject}</Box>
              </Box>
            </Box>
          </Box>
          <Box w="50%" textAlign="end">
            <Box fontSize={"0.6875rem"} fontWeight={'350'}>{formatDate(item.submittedOn)}</Box>
            <Box>
              <Badge backgroundColor="#dee8fc" fontSize="9px" color="#5887f0">
                {item.priority}
              </Badge>
            </Box>
          </Box>
        </Box>
        ))}
      </Box>
      {/* Ticket Listing Starts Here */}
    </Box>
  );
};
export default SupportRequests;

