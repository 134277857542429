import React, { Component } from 'react'
import { Input, Label } from 'reactstrap'
class KeyValuePair extends Component {
	constructor(props) {
		super(props)
		this.state = {
			field: props.field,
			key: '',
			value: '',
			required: false,
		}
	}

	handleKeyChange(e) {
		this.setState({
			key: e.target.value,
		})
	}
	handleValueChange(e) {
		this.setState({
			value: e.target.value,
		})
	}

	handleAddItem(name, field) {
		if (field.create) {
			this.props.handleAddItem({ name, field, createModalOpen: true })
			return
		}
		let value = this.state?.value
		if (field.captureRequired) {
			value = { value, required: this.state?.required }
		}
		if (this.state.key && this.state.value) this.props.handleAddItem({ name, key: this.state.key, value: value })

		this.setState({
			key: '',
			value: '',
			required: false,
		})
	}

	render() {
		const { field } = this.state
		const { t } = this.props
		return (
			<div className={'custom-form-grp row clearfix'}>
				<div className={field.labelClass || 'col-sm-3'}>
					<Label style={{fontSize:"14px", color:"#374151", fontWeight:"500", lineHeight:"normal"}} for={field.name}>
						{t(field.label)} <sup>{field.required && '*'}</sup>
					</Label>
				</div>
				{!field.create && (
					<>
						<div className={field.classes || `col-sm-3`}>
							<Input name={`${field.name}_key`} placeholder={t(field.keyLabel || 'Key')} value={t(this.state.key)} onChange={e => this.setState({ key: e.target.value })}></Input>
						</div>
						<div className={field.classes || `col-sm-3`}>
							<Input
								name={`${field.name}_value`}
								placeholder={t(field.valueLabel || 'Value')}
								value={this.state.value}
								onChange={e => this.setState({ value: e.target.value })}></Input>
						</div>
					</>
				)}
				{field.captureRequired && (
					<div className="col-sm-2 align-self-center">
						<div className="row">
							<div className="align-self-center">{t(`Reqd?`)}</div>
							<div className={`align-self-center`}>
								<Input
									name={`${field.name}_reqd`}
									// placeholder={t(field.valueLabel || 'Value')}
									type="checkbox"
									checked={this.state.required}
									onClick={e => this.setState({ required: !this.state?.required })}></Input>
							</div>
						</div>
						{/* <Label for={`${field.name}_reqd`}>*</Label> */}
					</div>
				)}
				<div className={field.classes || `col-sm-1 align-self-center`}>
					<span onClick={this.handleAddItem.bind(this, field.name, field)}>
						<i className="fa fa-plus"></i>
					</span>
				</div>
			</div>
		)
	}
}

export default KeyValuePair
