import React, { useState, useEffect } from 'react'
import {
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useToast,
  Icon,
  DrawerHeader,
  Tabs, TabList, TabPanels, Tab, TabPanel,Text, useBoolean
} from '@chakra-ui/react'
import { useDisclosure } from '@chakra-ui/react'
import { QuestionOutlineIcon } from '@chakra-ui/icons'
import { Box } from '@chakra-ui/react'
import { identityServices } from '..'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import { Tooltip } from '@chakra-ui/react';
import styled from 'styled-components';
//import './Drawer.css'
import {BiHelpCircle} from 'react-icons/bi'
import SupportRequests from './SupportRequests'

// const CustomTabList = styled(TabList)`
//     background-color: #f2f3f4;
//     border-radius: 6px;
//     padding: 8px;
//     column-gap: 5px;
//     justify-content: space-around !important;
//     height: 45px;
//     > button {
//       border: 0 !important;
//       &:focus {
//         box-shadow: none;
//       }
//       &[tabindex='0'] {
//         padding-left: 25px;
//         padding-right: 25px;
//         background-color: #fdfdfd;
//         color: black;
//         border-radius: 6px;
//       }
//     }
// `;

// const drawerHeaderStyles = {
//   top: '20px',
//   zIndex: 111,
//   backgroundColor: '#fff',
//   width: 'calc(100% - 50px)'
// };
// const tabListStyles = {
//   top: '83px',
//   left: '20px',
//   right: '20px',
//   zIndex: 111,
//   display:'flex',
  
// };
// const scrollbar = {
//   '&::-webkit-scrollbar' :{
//     width: '5px',
//   },
//   '&::-webkit-scrollbar-track': {
//     background: '#f1f1f1',
//   },
//   '&::-webkit-scrollbar-thumb': {
//     background: '#888',
//     borderRadius: '5px'
//   },
// }


export default function DrawerHelper(props) {
  const { profile } = props || null;
  const [flag, setFlag] = useBoolean(false)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = React.useRef()

  const [data, setData] = useState({
    quickHelpContent: '',
    support: '',
    iframe: '',
  });
  const toast = useToast()
	useEffect(() => {
		if (profile?.roles?.some((role) => ['tenant_admin', 'Tenant Admin','ticket_admin', 'Ticket Admin'].includes(role))) {
			setFlag.on();
		} else {
			setFlag.off();
		}
	}, [profile, setFlag]);
  async function fetchData() {
    let apiUrl = sessionStorage.getItem('apiUrl')
    let pathName = window.location.pathname.split('/')
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      url: `${apiUrl}/botmgmt-service/HelpContent`,
    }
    const requestOptionsSupportBot = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      url: `${apiUrl}/BotConfig/findWithParentAndCountAll?$filter=configType eq 'defaultSetting' and fullName eq 'supportBotUrl' and status eq 'Published'`,
    }
    if (pathName.length <= 2) {
      requestOptions.url += `?$filter=applocation eq '${pathName[1]}'`;
    } else if (pathName.length > 2) {
      requestOptions.url += `?$filter=appname eq '${pathName[1]}' and applocation eq '${pathName[2]}'`;
    }

    try {
      const res = await identityServices.identityApi.request(requestOptions);
      const resSupportBot = await identityServices.identityApi.request(requestOptionsSupportBot);
      if (resSupportBot.data && resSupportBot.data.length > 0) {
        const support = resSupportBot.data[0]
        setData(prev => ({
          ...prev,
          support: resSupportBot.data[0],
          iframe: `<iframe src="${support?.properties?.url}?isSupportBot[0]=${props.supportBotProps.tenantId}&isSupportBot[1]=${props.supportBotProps.email}" scrolling="no" width="100%" height="100%" style="" frameBorder="0" ></iframe>`
        }))
      }
      if (res.data && res.data.length > 0) {
        setData(prev => ({
          ...prev,
          quickHelpContent: res.data[0].content
        }))
      } else {
          setData(prev => ({
              ...prev,
              quickHelpContent: `We’re here to provide you with the assistance and resources you need to effectively manage and maintain your employee support operations using [Rezolve.ai](https://www.rezolve.ai) bot application. \n\n </br> <div style="border-left: 4px solid blue; height: 18px; padding-left: 10px; border-radius: 3px; margin-left: 0px;"> <strong style="margin-left: 5px;">Frequently Asked Questions (FAQs):</strong> </div>  \nFor any frequently asked questions, we request you to refer to our [Help-Center](https://www.rezolve.ai/help-center) that we keep updated with frequent customer queries and updates about the product. You can access the Help Center at the following link – [Rezolve.ai|help-center](https://www.rezolve.ai/help-center) \n\n</br> If you have any other questions or need further assistance, please don’t hesitate to reach out to your dedicated customer service manager. We are committed to ensuring your success. </br></br> Sincerely, </br> Team [Rezolve.ai](https://www.rezolve.ai)`
          }))
      }      
      onOpen()
    } catch (err) {
      toast({
        title: 'Error',
        description: err.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
  }

  const handleClick = () => {
    fetchData()
  }

  return (
    <>
      <Tooltip label="Help" aria-label="Help Tooltip">
        <Box>
          <BiHelpCircle
            boxSize={6}
            fontSize={'25px'}
            cursor="pointer"
            color="#94a3b8"
            _hover={{ textDecoration: 'underline' }}
            onClick={handleClick}
          />
        </Box>

      </Tooltip>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md" fontFamily='Inter'>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Icon
              as={BiHelpCircle}
              color="blue"
              boxSize={6}
              data-testid="Bihelp"
              onClick={onOpen}
              cursor="pointer"
            />
          </DrawerHeader>
          <DrawerBody fontSize="14px">
            <Box fontWeight="bold">Help Center</Box>
            <Box fontSize="13px" color="gray" mb={2}>
              Find answers and get live support for your questions.
            </Box>
            <Tabs variant="unstyled">
              <TabList
                bg="rgb(242, 243, 244)"
                color="gray"
                p="4px"
              >
                <Tab
                  _selected={{ color: 'black', bg: 'white' }}
                  fontSize="13px"
                  borderRadius="6px"
                >
                  Quick Help
                </Tab>
                {/* {flag && (<Tab
                  _selected={{ color: 'black', bg: 'white' }}
                  fontSize="13px"
                  borderRadius="6px"
                >
                  AI Chat Bot
                </Tab>)} */}
                {flag && (<Tab
                  _selected={{ color: 'black', bg: 'white' }}
                  fontSize="13px"
                  borderRadius="6px"
                >
                  Request Support
                </Tab>)}
              </TabList>
              <TabPanels >
                <TabPanel paddingLeft={0} >
                  <ReactMarkdown children={data.quickHelpContent} rehypePlugins={[rehypeRaw]} />
                </TabPanel>
                {/* {flag && (<TabPanel paddingLeft={0} >
                 {
                    data.iframe && (<div style={{"width":"100%","height":"calc(100vh - 180px)","position":"unset"}} dangerouslySetInnerHTML={{ __html: data.iframe }} />)
                  }
                </TabPanel>)} */}
                {flag && (<TabPanel paddingLeft={0} paddingRight={0} >
                 <SupportRequests {...props}/>
                </TabPanel>)}
              </TabPanels>
            </Tabs>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

    </>
  )
}